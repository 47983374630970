import React from "react";
import { useRouteMatch } from "react-router-dom";
import config from "../config";

const Reports: React.FC = () => {
  let match = useRouteMatch(config.routes.reports);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexFlow: "row",
          columnGap: "20px",
          alignItems: "center",
          justifyContent: "center",
          margin: "20px",
        }}
      >
        <h5> Report Generation</h5>
      </div>
      <div
        style={{
          display: "flex",
          flexFlow: "row",
          columnGap: "20px",
          alignItems: "center",
          justifyContent: "center",
          margin: "20px",
          rowGap: "20px",
          flexWrap: "wrap",
        }}
      >
        <a
          type="button"
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_NODE_URL}${match.params.clientId}/accountsReport?filename=accountsreport`}
          style={{ textDecoration: "none" }}
        >
          <button
            style={{
              backgroundColor: "#0098ff",
              color: "white",
              borderRadius: "5px",
              boxSizing: "border-box",
              border: "0",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "250px",
            }}
          >
            Accounts report
          </button>
        </a>
        <a
          type="button"
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_NODE_URL}${match.params.clientId}/settlementReport?filename=settlementreport`}
          style={{ textDecoration: "none" }}
        >
          <button
            style={{
              backgroundColor: "#0098ff",
              color: "white",
              borderRadius: "5px",
              border: "0",
              height: "40px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "250px",
            }}
          >
            SettleMent Report
          </button>
        </a>
        <a
          type="button"
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_NODE_URL}${match.params.clientId}/salesSummaryReport?filename=salessummaryreport`}
          style={{ textDecoration: "none" }}
        >
          <button
            style={{
              backgroundColor: "#0098ff",
              color: "white",
              borderRadius: "5px",
              border: "0",
              height: "40px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "250px",
            }}
          >
            Sales Summary Report
          </button>
        </a>
        <a
          type="button"
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_NODE_URL}${match.params.clientId}/salesByPriceReport?filename=salesbypricereport`}
          style={{ textDecoration: "none" }}
        >
          <button
            style={{
              backgroundColor: "#0098ff",
              color: "white",
              borderRadius: "5px",
              border: "0",
              height: "40px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "250px",
            }}
          >
            Sales By Price Report
          </button>
        </a>
        <a
          type="button"
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_NODE_URL}${match.params.clientId}/financialAnalysisReport?filename=financialanalysisreport`}
          style={{ textDecoration: "none" }}
        >
          <button
            style={{
              backgroundColor: "#0098ff",
              color: "white",
              borderRadius: "5px",
              border: "0",
              height: "40px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "250px",
            }}
          >
            Financial Analysis Report
          </button>
        </a>
        <a
          type="button"
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_NODE_URL}${match.params.clientId}/salesByPatronReport?filename=salesbypatronreport`}
          style={{ textDecoration: "none" }}
        >
          <button
            style={{
              backgroundColor: "#0098ff",
              color: "white",
              borderRadius: "5px",
              border: "0",
              height: "40px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "250px",
            }}
          >
            Sales By Patron Report
          </button>
        </a>
        <a
          type="button"
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_NODE_URL}${match.params.clientId}/inventoryReport?filename=inventoryavailablereport`}
          style={{ textDecoration: "none" }}
        >
          <button
            style={{
              backgroundColor: "#0098ff",
              color: "white",
              borderRadius: "5px",
              border: "0",
              height: "40px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "250px",
            }}
          >
            Inventory Report
          </button>
        </a>
        <a
          type="button"
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_NODE_URL}${match.params.clientId}/returnsReport?filename=returnsreport`}
          style={{ textDecoration: "none" }}
        >
          <button
            style={{
              backgroundColor: "#0098ff",
              color: "white",
              borderRadius: "5px",
              border: "0",
              height: "40px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "250px",
            }}
          >
            Returns Report
          </button>
        </a>
        <a
          type="button"
          href={`${process.env.REACT_APP_NODE_URL}${match.params.clientId}/journalcsv`}
          style={{ textDecoration: "none" }}
        >
          <button
            style={{
              backgroundColor: "#0098ff",
              color: "white",
              borderRadius: "5px",
              border: "0",
              height: "40px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "250px",
            }}
          >
            Journal CSV
          </button>
        </a>
        <a
          type="button"
          href={`${process.env.REACT_APP_NODE_URL}${match.params.clientId}/invoicecsv`}
          style={{ textDecoration: "none" }}
        >
          <button
            style={{
              backgroundColor: "#0098ff",
              color: "white",
              borderRadius: "5px",
              border: "0",
              height: "40px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "250px",
            }}
          >
            Invoice CSV
          </button>
        </a>
      </div>
    </>
  );
};

export default Reports;
