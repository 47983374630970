import React from 'react';

import ClearCache from "../components/ClearCache";

const Settings: React.FC = () => {
    return (<>
                <div style={{
                    marginBottom: 10,
                    marginLeft: 10,
                }}>
                    <ClearCache/>
                </div>
            </>
            );
}

export default Settings;