import ky from "ky";
import keycloak from "../keycloak";
import config from "../config/index";
function loadSalesData(data) {
  const { paging, pageIndex, clientId } = data;
  return new Promise((resolve, reject) => {
    ky.post(
      config.clients[clientId].domain.concat(
        config.clients[clientId].api.salesByClient
      ),
      { json: { clientid: clientId } }
    )
      .json()
      .then((data) => {
        console.log(paging, pageIndex);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function refundTxn(data) {
  const { clientId, txnid } = data;
  const user = {
    id: keycloak.tokenParsed.sub,
    email: keycloak.tokenParsed.email,
    firstname: keycloak.tokenParsed.given_name,
    lastname: keycloak.tokenParsed.family_name,
  };
  return new Promise((resolve, reject) => {
    ky.post(
      config.clients[clientId].domain.concat(
        config.clients[clientId].api.refundByClient
      ),
      { json: { clientid: clientId, txnid, user } }
    )
      .json()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function resendEmail(data) {
  const { clientId, transaction } = data;
  return new Promise((resolve, reject) => {
    ky.post(
      config.clients[clientId].domain.concat(
        config.clients[clientId].api.resendEmailByClient
      ),
      {
        json: {
          jobType: "email",
          ...transaction,
        },
      }
    )
      .json()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function updateEmail(data) {
  const { clientId, transaction } = data;
  return new Promise((resolve, reject) => {
    ky.post(
      config.clients[clientId].domain.concat(
        config.clients[clientId].api.updateEmailByClient
      ),
      {
        json: {
          ...transaction,
        },
      }
    )
      .json()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function loadCollectionsData(data) {
  const { paging, pageIndex, clientId } = data;
  return new Promise((resolve, reject) => {
    ky.post(
      config.clients[clientId].domain.concat(
        config.clients[clientId].api.collectionsByClient
      ),
      { json: { clientid: clientId } }
    )
      .json()
      .then((data) => {
        console.log(paging, pageIndex);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function loadOfferCodesData(data) {
  const { paging, pageIndex, clientId } = data;
  return new Promise((resolve, reject) => {
    ky.post(
      config.clients[clientId].domain.concat(
        config.clients[clientId].api.offerCodesByClient
      ),
      { json: { clientid: clientId } }
    )
      .json()
      .then((data) => {
        console.log(paging, pageIndex);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function txnInfo(data) {
  const { clientId, txnid } = data;
  return new Promise((resolve, reject) => {
    ky.get(config.clients[clientId].api.txnInfo + txnid)
      .json()
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export default {
  refundTxn,
  loadSalesData,
  loadCollectionsData,
  txnInfo,
  loadOfferCodesData,
  resendEmail,
  updateEmail,
};
