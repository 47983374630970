import React, { useState } from "react";
import ApiService from "../services/ApiService";

function ResendEmail(props) {
  const [disableButton, setDisableButton] = useState(false);
  return (
    <button
      className="bg-red-400 hover:bg-red-300 rounded text-white p-2 pl-4 pr-4"
      disabled={disableButton}
      onClick={() => {
        setDisableButton(true);
        ApiService.resendEmail({
          transaction: {
            reference_id: props.transaction.payment_reference,
            id: props.transaction.id,
            client_id: props.clientId,
            product_id: props.transaction.product_id,
            user_name: props.transaction.firstname + props.transaction.lastname,
            user_id: props.transaction.user_id,
            total: props.transaction.payment_amount,
            txn_time: props.transaction.created_date,
            to: props.transaction.email,
          },
          clientId: props.clientId,
        })
          .then((data) => {
            setDisableButton(false);
            alert("Resend Email success!");
          })
          .catch((err) => {
            setDisableButton(false);
            alert(err);
          });
      }}
    >
      <p className="font-semibold text-xs">Resend Mail</p>
    </button>
  );
}

export default ResendEmail;
