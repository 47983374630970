const configData = {
  default: {
    settings: {
      storage_keys: [
        { page: "OfferCodes", key: "offercode-list-store" },
        { page: "SalesList", key: "sales-list-stores" },
        { page: "FinanceReconcile", key: "finance-list-store" },
      ],
    },
  },
  production: {
    bouncer: "/admin/bouncer_p.json",
    routes: {
      base: "/",
      root: "/admin/",
      client: "/admin/client/",
      clientwithparams: "/admin/client/:clientId/",
      sales: "/admin/client/:clientId/sales",
      finance: "/admin/client/:clientId/financereconcile",
      offercode: "/admin/client/:clientId/offercode",
      settings: "/admin/client/:clientId/settings",
      reports: "/admin/client/:clientId/reports",
    },
    clients: {
      SWF2020: {
        domain: "https://dchadmin.sistic.com",
        api: {
          salesByClient: "/api/sales/byClient",
          refundByClient: "/api/payments/braintree/return",
          txnInfo: "/api/payments/braintree/info/",
          collectionsByClient: "/api/sales/collectionsByClient",
          offerCodesByClient: "/api/offers/offerCodesByClient",
          resendEmailByClient: "/api/queues/addJob",
          updateEmailByClient: "/api/transactions/changeEmailAddress",
        },
      },
      JJSANCTUARYFINALE: {
        domain: "https://dchadmin.sistic.com",
        api: {
          salesByClient: "/api/sales/byClient",
          refundByClient: "/api/payments/braintree/return",
          txnInfo: "/api/payments/braintree/info/",
          collectionsByClient: "/api/sales/collectionsByClient",
          offerCodesByClient: "/api/offers/offerCodesByClient",
          resendEmailByClient: "/api/queues/addJob",
          updateEmailByClient: "/api/transactions/changeEmailAddress",
        },
      },
    },
  },
  staging: {
    bouncer: "/admin/bouncer_d.json",
    routes: {
      base: "/",
      root: "/admin/",
      client: "/admin/client/",
      clientwithparams: "/admin/client/:clientId/",
      sales: "/admin/client/:clientId/sales",
      finance: "/admin/client/:clientId/financereconcile",
      offercode: "/admin/client/:clientId/offercode",
      settings: "/admin/client/:clientId/settings",
      reports: "/admin/client/:clientId/reports",
    },
    clients: {
      SWF2020: {
        domain: "https://dchadmin-stg.sistic.com",
        api: {
          salesByClient: "/api/sales/byClient",
          refundByClient: "/api/payments/braintree/return",
          txnInfo: "/api/payments/braintree/info/",
          collectionsByClient: "/api/sales/collectionsByClient",
          offerCodesByClient: "/api/offers/offerCodesByClient",
          resendEmailByClient: "/api/queues/addJob",
          updateEmailByClient: "/api/transactions/changeEmailAddress",
        },
      },
      JJSANCTUARYFINALE: {
        domain: "https://dchadmin-stg.sistic.com",
        api: {
          salesByClient: "/api/sales/byClient",
          refundByClient: "/api/payments/braintree/return",
          txnInfo: "/api/payments/braintree/info/",
          collectionsByClient: "/api/sales/collectionsByClient",
          offerCodesByClient: "/api/offers/offerCodesByClient",
          resendEmailByClient: "/api/queues/addJob",
          updateEmailByClient: "/api/transactions/changeEmailAddress",
        },
      },
    },
  },
  development: {
    bouncer: "/admin/bouncer_d.json",
    routes: {
      base: "/",
      root: "/admin/",
      client: "/admin/client/",
      clientwithparams: "/admin/client/:clientId/",
      sales: "/admin/client/:clientId/sales",
      finance: "/admin/client/:clientId/financereconcile",
      offercode: "/admin/client/:clientId/offercode",
      settings: "/admin/client/:clientId/settings",
      reports: "/admin/client/:clientId/reports",
    },
    clients: {
      SWF2020: {
        domain: "https://dchadmin-stg.sistic.com",
        api: {
          salesByClient: "/api/sales/byClient",
          refundByClient: "/api/payments/braintree/return",
          txnInfo: "/api/payments/braintree/info/",
          collectionsByClient: "/api/sales/collectionsByClient",
          offerCodesByClient: "/api/offers/offerCodesByClient",
          resendEmailByClient: "/api/queues/addJob",
          updateEmailByClient: "/api/transactions/changeEmailAddress",
        },
      },
      JJSANCTUARYFINALE: {
        domain: "https://dchadmin-stg.sistic.com",
        api: {
          salesByClient: "/api/sales/byClient",
          refundByClient: "/api/payments/braintree/return",
          txnInfo: "/api/payments/braintree/info/",
          collectionsByClient: "/api/sales/collectionsByClient",
          offerCodesByClient: "/api/offers/offerCodesByClient",
          resendEmailByClient: "/api/queues/addJob",
          updateEmailByClient: "/api/transactions/changeEmailAddress",
        },
      },
    },
  },
};

let config = configData[process.env.REACT_APP_NODE_ENV];
config.default = configData["default"];
export default config;
