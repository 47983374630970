import React, { useState } from "react";
import ApiService from "../services/ApiService";

function RefundAction(props) {
  const [disableButton, setDisableButton] = useState(false);
  if (!props.iscard) {
    return <div></div>;
  }
  if (
    (!props.refundId || props.refundId === "NA") &&
    props.currentStatus !== "voided"
  ) {
    return (
      <button
        className="bg-red-400 hover:bg-red-300 rounded text-white p-2 pl-4 pr-4"
        disabled={disableButton}
        onClick={() => {
          setDisableButton(true);
          ApiService.refundTxn({
            txnid: props.txnToRefund,
            clientId: props.clientId,
          })
            .then((data) => {
              setDisableButton(false);
              alert("Refund success! \n\nTransaction is " + data.status);
            })
            .catch((err) => {
              setDisableButton(false);
              alert(err);
            });
        }}
      >
        <p className="font-semibold text-xs">Refund</p>
      </button>
    );
  } else if (props.currentStatus === "voided") {
    return (
      <button className="bg-blue-500 hover:bg-gray-500 rounded text-white p-2 pl-4 pr-4 ">
        <p className="font-semibold text-xs ">Voided</p>
      </button>
    );
  } else {
    return (
      <button
        className="bg-blue-500 hover:bg-gray-500 rounded text-white p-2 pl-4 pr-4 "
        onClick={() => {
          ApiService.txnInfo({
            txnid: props.refundId,
            clientId: props.clientId,
          })
            .then((data) => {
              alert(
                "Refund for " + props.txnToRefund + ", status is " + data.status
              );
            })
            .catch((err) => {
              alert(err);
            });
        }}
      >
        <p className="font-semibold text-xs ">Refund Info</p>
      </button>
    );
  }
}

export default RefundAction;
