import React from 'react';
import { Route} from 'react-router-dom';
import AuthorizedFunction from "./AuthorizedFunction";

export function PrivateRoute({component: Component, roles, ...rest}) {
    return (
        <Route
            {...rest}
            render={props => {
                return AuthorizedFunction(roles)
                    ? <Component {...props} />
                    : <Route exact path="/" render={() => (window.location = "https://google.com")} />
            }}
        />
    )
}