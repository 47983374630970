import React, { useState } from 'react';

import { ITableProps, kaReducer, Table } from 'ka-table';
import {DataType, FilteringMode, SortingMode} from 'ka-table/enums';
import { DispatchFunc } from 'ka-table/types';
import {useRouteMatch} from "react-router-dom";
import config from "../config";
import ApiService from "../services/ApiService";
import {CSVLink} from "react-csv";
import {search} from "ka-table/actionCreators";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import {Download} from "react-feather";

const OPTION_KEY = 'offercode-list-store';

const columns = [
    {key: 'id', title: 'Id', style: { width: 350 }, dataType: DataType.String},
    {key: 'user', title: 'User', style: { width: 200 }, dataType: DataType.Object},
    {key: 'product', title: 'Product', style: { width: 180 }, dataType: DataType.String},
    {key: 'amount', title: 'Amount', style: { width: 80 }, dataType: DataType.Number},
    {key: 'reference', title: 'Reference', style: { width: 120 }, dataType: DataType.String},
    {key: 'updatedat', title: 'Updated at', style: { width: 150 }, dataType: DataType.Date},
];

const defaultOption: ITableProps = {
    columns,
    //editingMode: EditingMode.Cell,
    rowKeyField: 'id',
    sortingMode: SortingMode.Single,
    paging: {
        enabled: true,
        pageIndex: 0,
        pageSize: 10,
    },
    search: ({ searchText, rowData, column }) => {
        switch (column.key) {
            case 'user':
                if(!!rowData.user)
                    return (rowData.user.email.includes(searchText));
                break;
            case 'id':
                return (encodeURI(btoa(rowData.id)).includes(searchText))
            default:
                break;
        }
    },
    filteringMode: FilteringMode.FilterRow,
    format: ({column, value}) => {
        switch (column.dataType) {
            case DataType.Date:
                return value && value.toLocaleString('en-SG');
            case DataType.Number:
                return value && value.toFixed(2);
            default:
                break;
        }
        switch (column.key) {
            case 'id':
                return encodeURI(btoa(value));
            case 'user':
                if(!!value){
                    return  value.email;
                }
                break;
            default:
                break;
        }
    },
};

const tablePropsInit: ITableProps = {...defaultOption, ...JSON.parse(localStorage.getItem(OPTION_KEY) || '0')};

const OfferCodes: React.FC = () => {
    const [offerCodes, setOfferCodes] = React.useState([]);
    let match = useRouteMatch(config.routes.offercode);
    const [tableProps, changeTableProps] = useState(tablePropsInit);
    const dispatch: DispatchFunc = (action) => {
        changeTableProps((prevState: ITableProps) =>{
            const newState = kaReducer(prevState, action);
            const { data, ...settingsWithoutData } = newState;
            localStorage.setItem(OPTION_KEY, JSON.stringify(settingsWithoutData));
            return newState;
        });
    };
    if (offerCodes.length === 0) {
        ApiService.loadOfferCodesData({clientId: match.params.clientId}).then(data=>{
            setOfferCodes(data.offercode)
        });
    }
    tablePropsInit.data = offerCodes;
    return (
        <>
            <div style={{
                marginBottom: 10,
                marginLeft: 10,
            }}>
                <div className="pb-3">
                    <div className="bg-white rounded-full  flex justify-between items-center shadow-xl">
                        <input className='top-element w-full ml-2 py-4 px-6 text-gray-700 leading-tight focus:outline-none'
                               id="search"
                               type="search" placeholder="Search" defaultValue={tableProps.searchText}
                               onChange={(event) => {
                                   dispatch(search(event.currentTarget.value));
                               }} />
                        <div className="p-4">
                            <button
                                className="bg-blue-500 text-white rounded-full p-2 hover:bg-blue-400 focus:outline-none w-12 h-12 flex items-center justify-center">
                                <SearchIcon/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Table
                {...tableProps}
                childComponents={{
                    dataRow: {
                        elementAttributes: ({ rowData }) => ({
                            style: {
                                backgroundColor: rowData.isused===false ? 'rgba(0, 255, 0, 0.1)' : 'rgba(255, 0, 0, 0.1)'
                            },
                            title: `${rowData.id}: ${rowData.user}`
                        })
                    }
                }}
                dispatch={dispatch}
            />
            <CSVLink
                data={offerCodes.map(t=>({
                    id: encodeURI(btoa(t.id)),
                    product: t.product && t.product,
                    amount: t.amount && parseFloat(t.amount,2),
                    reference: t.reference,
                    updatedat: t.updatedat && new Date(t.updatedat).toLocaleString("en-SG"),
                    user: t.user && t.user.email
                }))}
                headers={tableProps.columns.map(c => ({label: c.title, key: c.key}))}
                filename={match.params.clientId + '-offercodes-data.csv'}
                enclosingCharacter={''}
                separator={';'}>
                <Button className="bg-teal-500 hover:bg-red-300 rounded text-white p-2 pl-4 pr-4 mt-2"
                        startIcon={<Download/>}>CSV
                </Button>
            </CSVLink>
            <CSVLink className="pl-6"
                data={offerCodes.map(t=>({
                    url: config.clients[match.params.clientId].domain+'/grouppass/?id='+encodeURI(btoa(t.id)),
                    reference: t.reference,
                    product: t.product && t.product,
                    amount: t.amount && parseFloat(t.amount,2),
                }))}
                headers={[{label:"URL",key:"url"},
                    {label:"Invoice",key:"reference"},
                    {label:"Product",key:"product"},
                    {label:"Price",key:"amount"},
                ]}
                filename={match.params.clientId + '-offercodeurl-distribution-data.csv'}
                enclosingCharacter={''}
                separator={';'}>
                <Button className="bg-indigo-400 hover:bg-red-300 rounded text-white p-2 pl-4 pr-4 mt-2"
                        startIcon={<Download/>}>Group-Pass-List
                </Button>
            </CSVLink>
        </>
    );
};

export default OfferCodes;