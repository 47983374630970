import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import { Route, Switch } from "react-router-dom";
import SideNavigation from "./components/SideNavigation";
import config from "./config/index";
import keycloak from "./keycloak";
import SalesList from "./pages/SalesList";
import OfferCodes from "./pages/OfferCodes";
import FinanceReconcile from "./pages/FinanceReconcile";
import Reports from "./pages/Reports";
import { PrivateRoute } from "./utils/PrivateRoute";
import Settings from "./pages/Settings";

const About = () => (
  <div>
    <h2>About</h2>
    ...
  </div>
);

function App() {
  const [authenticated, setAuthenticated] = useState(null);
  useEffect(() => {
    keycloak
      .init({
        onLoad: "login-required",
        checkLoginIframe: false,
      })
      .then((authenticated) => {
        setAuthenticated(authenticated);
        keycloak.onTokenExpired = function () {
          keycloak
            .updateToken(5)
            .then(function (refreshed) {
              if (refreshed) {
                console.log("Token was successfully refreshed");
              } else {
                console.log(
                  "Token is still valid " +
                    Math.round(
                      keycloak.tokenParsed.exp +
                        keycloak.timeSkew -
                        new Date().getTime() / 1000
                    ) +
                    " seconds"
                );
              }
            })
            .catch(function () {
              console.log(
                "Failed to refresh the token, or the session has expired"
              );
            });
        };
      });
  }, []);
  if (authenticated) {
    return (
      <div className="w-full">
        <Header />
        <main className="px-6 py-6 flex">
          <aside className="w-1/6">
            <SideNavigation />
          </aside>
          <div className="w-5/6 ml-4">
            <Switch>
              <PrivateRoute
                roles={["DCH_ADMIN", "DCH_CS", "DCH_FINANCE"]}
                path={config.routes.sales}
                component={SalesList}
              />
              <PrivateRoute
                roles={["DCH_ADMIN", "DCH_FINANCE"]}
                path={config.routes.finance}
                component={FinanceReconcile}
              />
              <PrivateRoute
                roles={["DCH_ADMIN", "DCH_CS"]}
                path={config.routes.offercode}
                component={OfferCodes}
              />
              <Route path={config.routes.settings} component={Settings} />
              <PrivateRoute
                roles={["DCH_ADMIN", "DCH_CS", "DCH_FINANCE"]}
                path={config.routes.reports}
                component={Reports}
              />
              <Route
                path="/admin/client/:clientId/transaction/:transactionId"
                component={About}
              />
              <Route
                path="/admin/client/:clientId/transaction/:transactionId/recording"
                component={About}
              />
              <Route
                path="/admin/client/:clientId/transaction/:page"
                component={About}
              />
            </Switch>
          </div>
        </main>
      </div>
    );
  } else {
    return <div>You are stranger.. Deal with bouncer!</div>;
  }
}

export default App;
