import React, { useState, useEffect } from "react";

import { ITableProps, kaReducer, Table } from "ka-table";
import { DataType, FilteringMode, SortingMode } from "ka-table/enums";
import { DispatchFunc } from "ka-table/types";
import { useRouteMatch } from "react-router-dom";
import config from "../config";
import ApiService from "../services/ApiService";
import { CSVLink } from "react-csv";
import { search } from "ka-table/actionCreators";
import SearchIcon from "@material-ui/icons/Search";
import { MonetizationOnRounded } from "@material-ui/icons";
import { ICellTextProps } from "ka-table/index";
import RefundAction from "../components/RefundAction";
import ResendEmail from "../components/ResendEmail";
import UpdateEmail from "../components/UpdateEmail";
import ShowPaymentMethod from "../components/ShowPaymentMethod";
import { Download } from "react-feather";
import Button from "@material-ui/core/Button";

const OPTION_KEY = "sales-list-stores";

const columns = [
  {
    key: "email",
    title: "User Id",
    style: { width: 200 },
    dataType: DataType.String,
  },
  {
    key: "firstname",
    title: "First Name",
    style: { width: 200 },
    dataType: DataType.String,
  },
  {
    key: "lastname",
    title: "Last Name",
    style: { width: 200 },
    dataType: DataType.String,
  },
  {
    key: "product_id",
    title: "Product",
    style: { width: 225 },
    dataType: DataType.String,
  },
  {
    key: "payment_amount",
    title: "$paid",
    style: { width: 100 },
    dataType: DataType.Number,
  },
  {
    key: "created_date",
    title: "Transacted at",
    style: { width: 200 },
    dataType: DataType.Date,
  },
  {
    key: "shippingaddress",
    title: "ShippingInfo",
    style: { width: 350 },
    dataType: DataType.Object,
  },
  { key: "show_card_info", style: { width: 90 } },
  {
    key: "creditcard",
    title: "Card Details",
    style: { width: 150 },
    dataType: DataType.Object,
  },
  {
    key: "id",
    title: "Txn #",
    style: { width: 100 },
    dataType: DataType.String,
  },
  {
    key: "payment_reference",
    title: "Reference #",
    style: { width: 100 },
    dataType: DataType.String,
  },
  {
    key: "refundid",
    title: "Refund Txn #",
    style: { width: 100 },
    dataType: DataType.String,
  },
  { key: "command_refund", style: { width: 100 } },
  { key: "command_resend_email", style: { width: 150 } },
  { key: "command_update_email", style: { width: 150 } },
];

const defaultOption: ITableProps = {
  columns,
  //editingMode: EditingMode.Cell,
  rowKeyField: "id",
  sortingMode: SortingMode.Single,
  paging: {
    enabled: true,
    pageIndex: 0,
    pageSize: 10,
  },
  filteringMode: FilteringMode.FilterRow,
  format: ({ column, value }) => {
    switch (column.dataType) {
      case DataType.Date:
        return value && value.toLocaleString("en-SG");
      case DataType.Number:
        return value && value.toFixed(2);
      default:
        break;
    }
    switch (column.key) {
      case "user_id":
        let emailOrId = value.split(":")[2];
        if (emailOrId === undefined) {
          emailOrId = value;
        }
        return emailOrId;
      case "creditcard":
        if (!!value) {
          const paymentInfo = JSON.parse(value);
          return paymentInfo.maskedNumber;
        }
        break;
      case "shippingaddress":
        if (!!value) {
          const addressInfo = value;
          return addressInfo;
        }
        break;
      default:
        break;
    }
  },
};

const tablePropsInit: ITableProps = {
  ...defaultOption,
  ...JSON.parse(localStorage.getItem(OPTION_KEY) || "0"),
};

const RefundCell: React.FC<ICellTextProps> = ({ rowData }) => {
  let match = useRouteMatch(config.routes.sales);

  return (
    <RefundAction
      iscard={rowData.creditcard !== undefined}
      currentStatus={rowData.payment_status}
      txnToRefund={rowData.id}
      clientId={match.params.clientId}
      refundId={rowData.refundid}
    />
  );
};

const ResendEmailCell: React.FC<ICellTextProps> = ({ rowData }) => {
  let match = useRouteMatch(config.routes.sales);

  return <ResendEmail clientId={match.params.clientId} transaction={rowData} />;
};

const UpdateEmailCell: React.FC<ICellTextProps> = ({ rowData }) => {
  let match = useRouteMatch(config.routes.sales);

  return <UpdateEmail clientId={match.params.clientId} transaction={rowData} />;
};

const PaymentMethodCell: React.FC<ICellTextProps> = ({ rowData }) => {
  if (!!rowData.creditcard) {
    const paymentInfo = JSON.parse(rowData.creditcard);
    return (
      <ShowPaymentMethod
        info={paymentInfo.maskedNumber}
        img={paymentInfo.imageUrl}
      />
    );
  } else {
    return (
      <div className="pl-3 xl:font-black">
        <MonetizationOnRounded className="xl" />
      </div>
    );
  }
};

const SalesList: React.FC = () => {
  console.log("ere");
  const [transactions, setTransactions] = React.useState([]);
  let match = useRouteMatch(config.routes.sales);
  const [tableProps, changeTableProps] = useState(tablePropsInit);
  useEffect(() => {
    console.log("table", tableProps, localStorage.getItem(OPTION_KEY));
  }, [tableProps]);
  const dispatch: DispatchFunc = (action) => {
    changeTableProps((prevState: ITableProps) => {
      const newState = kaReducer(prevState, action);
      const { data, ...settingsWithoutData } = newState;
      localStorage.setItem(OPTION_KEY, JSON.stringify(settingsWithoutData));
      return newState;
    });
  };
  if (transactions.length === 0) {
    ApiService.loadSalesData({ clientId: match.params.clientId }).then(
      (data) => {
        data.transaction.forEach((row) => {
          if (row.shippingaddress) {
            var shippingdata = JSON.parse(row.shippingaddress);
            shippingdata.line2 =
              shippingdata && typeof shippingdata.line2 !== "undefined"
                ? shippingdata.line2
                : "";
            row.shippingaddress =
              row.id +
              "," +
              shippingdata.recipientName +
              "," +
              shippingdata.countryCode +
              "," +
              shippingdata.state +
              "," +
              shippingdata.city +
              "," +
              shippingdata.line1 +
              "," +
              shippingdata.line2 +
              "," +
              shippingdata.postalCode;
          }
        });
        setTransactions(data.transaction);
      }
    );
  }
  tablePropsInit.data = transactions;
  return (
    <>
      <div
        style={{
          marginBottom: 10,
          marginLeft: 10,
        }}
      >
        <div className="pb-3">
          <div className="bg-white rounded-full  flex justify-between items-center shadow-xl">
            <input
              className="top-element w-full ml-2 py-4 px-6 text-gray-700 leading-tight focus:outline-none"
              id="search"
              type="search"
              placeholder="Search"
              defaultValue={tableProps.searchText}
              onChange={(event) => {
                dispatch(search(event.currentTarget.value));
              }}
            />
            <div className="p-4">
              <button className="bg-blue-500 text-white rounded-full p-2 hover:bg-blue-400 focus:outline-none w-12 h-12 flex items-center justify-center">
                <SearchIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Table
        {...tableProps}
        childComponents={{
          dataRow: {
            elementAttributes: ({ rowData }) => ({
              style: {
                backgroundColor:
                  (!rowData.refundid || rowData.refundid === "NA") &&
                  rowData.payment_status !== "voided"
                    ? "rgba(0, 255, 0, 0.05)"
                    : "rgba(255, 0, 0, 0.1)",
              },
              title: `${rowData.id}: ${rowData.payment_status}`,
            }),
          },
          cellText: {
            content: (props) => {
              switch (props.column.key) {
                case "command_refund":
                  return <RefundCell {...props} />;
                case "command_resend_email":
                  return <ResendEmailCell {...props} />;
                case "command_update_email":
                  return <UpdateEmailCell {...props} />;
                case "show_card_info":
                  return <PaymentMethodCell {...props} />;
                default:
                  break;
              }
            },
          },
        }}
        dispatch={dispatch}
      />
      <CSVLink
        data={transactions}
        headers={tableProps.columns.map((c) => ({
          label: c.title,
          key: c.key,
        }))}
        filename={match.params.clientId + "-sales-data.csv"}
        enclosingCharacter={""}
        separator={";"}
      >
        <Button
          className="bg-teal-500 hover:bg-red-300 rounded text-white p-2 pl-4 pr-4 mt-2"
          startIcon={<Download />}
        >
          .csv
        </Button>
      </CSVLink>
    </>
  );
};

export default SalesList;
