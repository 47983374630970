import React from 'react';
import logo from "../images/sistic-logo-small.png";
import Select from 'react-select';
import LoginSelect from './LoginStatus'
import {useRouteMatch, useHistory, Redirect} from "react-router-dom";
import config  from '../config/index'
import AuthorizedFunction from "../utils/AuthorizedFunction";

function Header() {
    let baseMatch = useRouteMatch(config.routes.base);
    let rootMatch = useRouteMatch(config.routes.root);
    let clientMatch = useRouteMatch(config.routes.client)
    let match = useRouteMatch(config.routes.clientwithparams);
    let history = useHistory();

    const [clients, setClients] = React.useState([])
    if (clients.length === 0) {
        /*
        TODO: integrate api to fetch all clients
        */
        setClients([{value:"JJSANCTUARYFINALE", label: "JJSANCTUARYFINALE"},{value: 'SWF2020', label: 'SWF2020'}])
    }

    if(!AuthorizedFunction(['DCH_ADMIN','DCH_CS','DCH_FINANCE'])){ return null; }
    if (clients.length === 0) return null;
    if (baseMatch.isExact){
        return (<Redirect to={config.routes.client + clients[0].value + "/sales"}/>)
    }
    if (rootMatch.isExact || clientMatch.isExact) {
        return (<Redirect to={config.routes.client + clients[0].value + "/sales"}/>)
    }
    if (match.isExact) {
        return (<Redirect to={config.routes.client + match.params.clientId + "/sales"}/>)
    }
    let selectedClient = clients.filter(s => s.value === match.params.clientId)[0];
    return (
        <div className="w-full flex justify-between py-4 px-6 items-stretch">
            <span>
                <img src={logo} className="h-16 "alt="Small logo"/>
                <LoginSelect/>
            </span>

            <div className="w-64">
                <Select defaultValue={selectedClient} options={clients} onChange={(data, e) => {
                    if (e.action === 'select-option') {
                        history.push(config.routes.client + data.value + "/sales")
                    }
                }}></Select>
            </div>
        </div>
    );
}

export default Header;

