import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Activity, DollarSign, Gift, Settings, Save } from "react-feather";
import config from "../config/index";
import AuthorizedElement from "./AuthorizedElement";

function NavigationItem(props) {
  var match = useRouteMatch(props.url);

  return (
    <AuthorizedElement roles={props.roles}>
      <li
        className={
          "my-1 rounded " +
          (match ? "bg-primary text-white" : "hover:bg-gray-300")
        }
      >
        <Link
          to={props.url}
          className={"px-4 py-3 text-lg w-full h-full flex align-center"}
        >
          <span>{props.icon}</span>
          <span className={"ml-2"}>{props.label}</span>
        </Link>
      </li>
    </AuthorizedElement>
  );
}

function SideNavigation(props) {
  let match = useRouteMatch(config.routes.clientwithparams);
  if (!match) return null;
  let clientId = match.params.clientId;
  let baseUrl = config.routes.clientwithparams;
  let items = [
    {
      label: "Sales",
      url: baseUrl + "sales",
      icon: <Activity />,
      roles: ["DCH_ADMIN", "DCH_FINANCE", "DCH_CS"],
    },
    {
      label: "Offer Codes",
      url: baseUrl + "offercode",
      icon: <Gift />,
      roles: ["DCH_ADMIN", "DCH_CS"],
    },
    {
      label: "Finance Reconcilation",
      url: baseUrl + "financereconcile",
      icon: <DollarSign />,
      roles: ["DCH_ADMIN", "DCH_FINANCE"],
    },
    {
      label: "Reports",
      url: baseUrl + "reports",
      icon: <Save />,
      roles: ["DCH_ADMIN", "DCH_FINANCE", "DCH_CS"],
    },
    /*        {
            label: "Heatmaps",
            url: baseUrl + "/heatmaps",
            icon: (<Map/>)
        },*/
    {
      label: "Site Setting",
      url: baseUrl + "settings",
      icon: <Settings />,
      roles: ["DCH_ADMIN", "DCH_FINANCE", "DCH_CS"],
    },
  ];

  return (
    <nav>
      <ul>
        {items.map((i) => (
          <NavigationItem
            key={i.label}
            label={i.label}
            url={i.url.replace(":clientId", clientId)}
            icon={i.icon}
            roles={i.roles}
          />
        ))}
      </ul>
    </nav>
  );
}

export default SideNavigation;
