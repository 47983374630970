import React from 'react';
import keycloak from '../keycloak'


export default () => {
    return (
        <div className='pt-2 text-l'>
            {!!keycloak.authenticated && (
                <div>

                <span className='text-blue-500 '>
                    {keycloak.tokenParsed.given_name}
                </span>
                <button className='underline pl-2 text-red-700'
                    type="button" onClick={() => keycloak.logout()} >
                Logout
                </button>
                </div>
            )}
        </div>
    )
}