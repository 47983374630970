import React from 'react';
import Button from "@material-ui/core/Button";
import {RefreshCw} from "react-feather";
import config from "../config";

function ClearCache(props) {
        return (
            <Button className="bg-red-400 hover:bg-red-300 rounded text-white p-2 pl-4 pr-4"
                    onClick={() => {
                        config.default.settings.storage_keys.forEach(function(item,index){
                            localStorage.removeItem(item.key);
                        });
                        alert('Cache cleared!');
                    }}
                    startIcon={<RefreshCw/>}
            >Clear Cache
            </Button>
        );
}

export default ClearCache;